import * as React from "react"

//styles
import "../../styles/css/styles.css"

import defaults from "../../components/dafaults"

// components
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import List from "../../components/List"
import Pagination from "../../components/Pagination"

export default function DicasTecnicas() {

  const [ lancamentos, setLancamentos ] = React.useState(null)
  const [ dicas, setDicas ] = React.useState(null)
  const [ currentPage ,  setCurrentPage ] = React.useState(1);

  const [ total , setTotal ] = React.useState(0);

  React.useLayoutEffect(() => {

      const requestOptions = {
        method: 'GET', 
        redirect: 'follow'
      };

      fetch(`${defaults.api.rest}posts?categories=191&per_page=24&page=${currentPage}`, requestOptions)
      .then(response => {
        setTotal(response.headers.get('x-wp-total'));
        return response.json()
      })
      .then(result => {
        if(dicas == null || dicas[currentPage] == undefined){
          setDicas({...dicas, [currentPage]: result })
        }
      })
      .catch(error => console.log('error', error));

  })

  React.useEffect(() => {

    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch(`${defaults.api.rest}posts?categories=191&per_page=24&page=${currentPage}`, requestOptions)
    .then(response => {
      setTotal(response.headers.get('x-wp-total'));
      return response.json();
    })
    .then(result => {
      if(dicas == null || dicas[currentPage] == undefined){
        setDicas({...dicas, [currentPage]: result })
      }
    })
    .catch(error => console.log('error', error));
  }, [currentPage])


  const lancamentoConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const dicasConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  if(dicas == null) return null

  return(
    <>
      <Header />
      {/* <Search /> */}
        <section className="dicasPage">
          <div className="container dicas-title">
              <h2>Boletins Técnicos</h2>
              {/* <div className="navigation">
                <a href="/dicas-tecnicas">Ver todas as dicas</a>
              </div> */}
          </div>
          <div className="dicas-list">
              {
                dicas == null ?
                    <span>Carregando</span>
                :
                    <>
                        {/* <List datas={specificSearchCustom != null && specificSearchCustom.length > 1 && specificSearchCustom || lancamentos[currentPage]} buttonLabel="Saiba Mais" path="/lancamentos" /> */}
                        <List datas={dicas[currentPage]} buttonLabel="Saiba Mais" path="/dicas" />
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={parseInt(total / 24)} />
                    </>
                }
          </div>
        </section>
      <Footer />
    </>
  )
}

export const Head = () => <title>Wega Motors</title>